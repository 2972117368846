import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="mtmob3">
      <img
        src="https://static.pexels.com/photos/6526/sea-beach-holiday-vacation-large.jpg"
        className="w-full h-[27rem]"
      />
    </div>
    <br />
    <Container>
      <div className="grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:gap-4 ">
        <div className="col-span-2 ...">
          <div className=" rounded  overflow-hidden shadow-lg">
            <img
              className="w-full h-[18rem]"
              src="/images/tech.png"
              alt="Sunset in the mountains"
            />
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
              <p className="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Voluptatibus quia, nulla! Maiores et perferendis eaque,
                exercitationem praesentium nihil.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="detail-mob">
            <details className="shadow-lg ">
              <summary>
                How can we do this?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon float-right"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </summary>
              <div className="details-info">
                <h2>This can have a title or image</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  convallis sodales erat vel accumsan. Nam eget massa nec sem
                  vulputate ullamcorper vel quis justo. Duis rhoncus tempor
                  tempus. Nulla facilisi.
                </p>
              </div>
            </details>
            <details className="shadow-lg">
              <summary>
                Does this require any JavaScript?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon float-right"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </summary>
              <div className="details-info">
                <p>
                  Lorem ipsum dolor sit amet,ulputate ullamcorper vel quis
                  justo. Duis rhoncus tempor tempus. Nulla facilisi. Maecenas
                  nulla ante, lacinia ac consectetur non, aliquet sollicitudin
                  libero. Quisque congue odio sodales dui fermentum ac laoreet
                  mauris eleifend. Nulla facilisi. Phasellus vel erat a ante
                  pharetra pharetra.
                </p>
              </div>
            </details>
            <details className="shadow-lg ">
              <summary>
                Is this accessible?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon float-right"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </summary>
              <div className="details-info">
                <h2>Lorem ipsum dolor sit amet</h2>
                <p>
                  Lorem ipsum dolor sit amec sem vulputate ullamcorper vel quis
                  justo. Duis rhoncus tempor tempus. Nulla facilisi. Maecenas
                  nulla ante, lacinia ac consectetur non, aliquet sollicitudin
                  libero. Quisque congue odio sodales dui fermentum ac laoreet
                  mauris eleifend. Nulla facilisi. Phasellus vel erat a ante
                  pharetra pharetra.
                </p>
              </div>
            </details>
            <details className="shadow-lg ">
              <summary>
                How can we do this?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon float-right"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </summary>
              <div className="details-info">
                <h2>This can have a title or image</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  convallis sodales erat vel accumsan. Nam eget massa nec sem
                  vulputate ullamcorper vel quis justo. Duis rhoncus tempor
                  tempus. Nulla facilisi. Maecenas nulla ante, lacinia ac
                  consectetur non, aliquet sollicitudin libero. Quisque congue
                  odio sodales dui fermentum ac laoreet mauris eleifend. Nulla
                  facilisi.
                </p>
                <img src="https://picsum.photos/900/600?random=1" alt="" />
                <p>Phasellus vel erat a ante pharetra pharetra.</p>
              </div>
            </details>
            <details className="shadow-lg ">
              <summary>
                How can we do this?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon float-right"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </summary>
              <div className="details-info">
                <h2>This can have a title or image</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  convallis sodales erat vel accumsan. Nam eget massa nec sem
                  vulputate ullamcorper vel quis justo. Duis rhoncus tempor
                  tempus. Nulla facilisi. Maecenas nulla ante, lacinia ac
                  consectetur non, aliquet sollicitudin libero. Quisque congue
                  odio sodales dui fermentum ac laoreet mauris eleifend. Nulla
                  facilisi.
                </p>
                <img src="https://picsum.photos/900/600?random=1" alt="" />
                <p>Phasellus vel erat a ante pharetra pharetra.</p>
              </div>
            </details>
          </div>
        </div>
      </div>
      <br />
      <div className="grid grid-cols-1  md:grid-cols-3 lg:gap-4">
        <div className="rounded overflow-hidden shadow-lg">
          <img
            className="w-full"
            src="/images/tech.png"
            alt="Sunset in the mountains"
          />
          <div className="px-6 py-2">
            <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
            <p className="text-gray-700 text-base">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatibus quia, nulla! Maiores et perferendis eaque,
              exercitationem praesentium nihil.
            </p>
          </div>

          <div className="grid  py-3   grid-cols-3  social ">
            <div>
              <svg
                className="w-6 h-6 text-blue-600 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
              </svg>
            </div>
            <div>
              <svg
                className="w-6 h-6 text-blue-300 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
              </svg>
            </div>
            <div>
              <span>
                <svg
                  className="w-6 h-6 text-blue-500 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className=" rounded overflow-hidden shadow-lg">
          <img
            className="w-full"
            src="/images/tech.png"
            alt="Sunset in the mountains"
          />
          <div className="px-6 py-2">
            <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
            <p className="text-gray-700 text-base">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatibus quia, nulla! Maiores et perferendis eaque,
              exercitationem praesentium nihil.
            </p>
          </div>
          <div className="grid grid-cols-3  py-3  social ">
            <div>
              <svg
                className="w-6 h-6 text-blue-600 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
              </svg>
            </div>
            <div>
              <svg
                className="w-6 h-6 text-blue-300 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
              </svg>
            </div>
            <div>
              <span>
                <svg
                  className="w-6 h-6 text-blue-500 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className=" rounded overflow-hidden shadow-lg">
          <img
            className="w-full"
            src="/images/tech.png"
            alt="Sunset in the mountains"
          />
          <div className="px-6 py-2">
            <div className="font-bold text-xl mb-2">The Coldest Sunset</div>
            <p className="text-gray-700 text-base">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatibus quia, nulla! Maiores et perferendis eaque,
              exercitationem praesentium nihil.
            </p>
          </div>
          <div className="grid grid-cols-3 py-3 social ">
            <div>
              <svg
                className="w-6 h-6 text-blue-600 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
              </svg>
            </div>
            <div>
              <svg
                className="w-6 h-6 text-blue-300 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
              </svg>
            </div>
            <div>
              <span>
                <svg
                  className="w-6 h-6 text-blue-500 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>

      <br></br>
    </Container>
  </Layout>
)

export default IndexPage
